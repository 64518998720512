// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-achtung-baustelle-js": () => import("./../src/pages/achtung-baustelle.js" /* webpackChunkName: "component---src-pages-achtung-baustelle-js" */),
  "component---src-pages-art-caps-footsteps-js": () => import("./../src/pages/art-caps-footsteps.js" /* webpackChunkName: "component---src-pages-art-caps-footsteps-js" */),
  "component---src-pages-cnc-moebel-js": () => import("./../src/pages/cnc-moebel.js" /* webpackChunkName: "component---src-pages-cnc-moebel-js" */),
  "component---src-pages-cyanotypie-js": () => import("./../src/pages/cyanotypie.js" /* webpackChunkName: "component---src-pages-cyanotypie-js" */),
  "component---src-pages-datenschutz-js": () => import("./../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-dimod-js": () => import("./../src/pages/dimod.js" /* webpackChunkName: "component---src-pages-dimod-js" */),
  "component---src-pages-ejs-js": () => import("./../src/pages/ejs.js" /* webpackChunkName: "component---src-pages-ejs-js" */),
  "component---src-pages-explore-to-move-js": () => import("./../src/pages/explore-to-move.js" /* webpackChunkName: "component---src-pages-explore-to-move-js" */),
  "component---src-pages-extented-js": () => import("./../src/pages/extented.js" /* webpackChunkName: "component---src-pages-extented-js" */),
  "component---src-pages-flortrait-js": () => import("./../src/pages/flortrait.js" /* webpackChunkName: "component---src-pages-flortrait-js" */),
  "component---src-pages-illustratoren-fuer-fluechtlinge-js": () => import("./../src/pages/illustratoren-fuer-fluechtlinge.js" /* webpackChunkName: "component---src-pages-illustratoren-fuer-fluechtlinge-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konzept-cranach-js": () => import("./../src/pages/konzept-cranach.js" /* webpackChunkName: "component---src-pages-konzept-cranach-js" */),
  "component---src-pages-notizhefte-voegel-js": () => import("./../src/pages/notizhefte-voegel.js" /* webpackChunkName: "component---src-pages-notizhefte-voegel-js" */),
  "component---src-pages-octopuz-js": () => import("./../src/pages/octopuz.js" /* webpackChunkName: "component---src-pages-octopuz-js" */),
  "component---src-pages-student-welcome-package-js": () => import("./../src/pages/student-welcome-package.js" /* webpackChunkName: "component---src-pages-student-welcome-package-js" */),
  "component---src-pages-ueber-mich-js": () => import("./../src/pages/ueber-mich.js" /* webpackChunkName: "component---src-pages-ueber-mich-js" */)
}

